require(['jquery', 'http'], function ($, http) {
	'use strict';

	var waitingOnResponse = false;

	$('.approved-status input').change(function (event) {
		if (waitingOnResponse)
		{
			event.preventDefault();
			return false;
		}
		waitingOnResponse = true;
		var $this = $(this);
		var $template = $this.parents('.template');
		var id = $template.attr('data-id');
		var approved = $this.prop('checked');
		http.post('/Build/BuildTemplateManagement/ToggleApproved?id=' + id + '&approved=' + approved).done(statusToggled);
	});

	var statusToggled = function (response) {
		waitingOnResponse = false;
		if (response.Success) {
			var entity = response.Entity;
			var $template = $('.template[data-id=' + entity.ID + ']');
			var $checkbox = $template.find('input');
			$checkbox.prop('checked', entity.Approved);
		}
	};


    // old code for selecting a specific color.  Not used as of 2/2/18 - TF
	$('.starting-project .color-dropdown select').change(function () {
		var $select = $(this);
		var colorID = parseInt($select.val());
		if (isNaN(colorID))
			return;
		var $project = $select.parents('.starting-project');
		var projectID = parseInt($project.attr('data-projectid'));
		if (isNaN(projectID))
			return;
        window.location = '/Build/BuildTemplateManagement/ModifyBuildTemplate?projectID=' + projectID + '&colorID=' + colorID;
    });


    // added 2/2/18 - tf - replace dropdown with a button and take first color, if exisits.  OTherwise lineart will be used
    $('.starting-project #Create-New').click(function () {
        var $button = $(this);
        var colorID = parseInt($button.attr('data-colorId'));
        var $project = $button.parents('.starting-project');
        var oneview = $project.attr('data-oneview');
        var oneviewtemplateid = $project.attr('data-oneviewtemplateid');            
        var projectID = parseInt($project.attr('data-projectid'));
        if (isNaN(projectID)) {
            return;
        }

        if (oneview == 'True') {
            window.location = '/Build/BuildTemplateManagement/ModifyOneViewBuildTemplate?projectID=' + projectID +  '&oneviewtemplateid=' + oneviewtemplateid;
        }
        else if (colorID == null || isNaN(colorID)) {
            window.location = '/Build/BuildTemplateManagement/ModifyBuildTemplate?projectID=' + projectID;
        }
        else {
            window.location = '/Build/BuildTemplateManagement/ModifyBuildTemplate?projectID=' + projectID + '&colorID=' + colorID;

        }
    });
});